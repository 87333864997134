<div class="profile-selector">
  <button color="primary" mat-flat-button [matMenuTriggerFor]="menuProfile">
    @if (!currentProfile) {
      Choix d'un profil
    }
    @if (currentProfile) {
      {{ currentProfile?.name }}
    }
    <span class="icon caret profile-selector__icon d-inline-flex mx-1"></span>
  </button>
  <mat-menu #menuProfile="matMenu" class="profile-selector__panel">
    <div
      aria-hidden="true"
      class="profile-selector__content p-3"
      (click)="$event.stopPropagation()"
      (keydown)="$event.stopPropagation()"
    >
      <mat-form-field [style.--mat-input-text]="customerConfig?.style?.hover">
        <mat-label>Recherche</mat-label>
        <input aria-label="Recherche.." matInput placeholder="Recherche" type="text" [(ngModel)]="term" />
      </mat-form-field>
      <div class="profile-selector__list py-3">
        <ng-container *appHasRole="[roles.ADMIN, roles.STANDARD]">
          @for (profile of profiles | filter: term : false; track profile; let even = $even; let odd = $odd) {
            <div
              class="profile-selector__line"
              [ngClass]="{ 'profile-selector__line--odd': odd, 'profile-selector__line--even': even }"
            >
              <div class="profile-selector__line py-1 px-3">
                <a title="{{ profile.name }}" [routerLink]="['/', profile.id]" (click)="toggleProfileSelector()"
                  ><span [innerHtml]="profile.name | highlightTerm: term"></span
                ></a>
                <a
                  title="{{ profile.name }}"
                  [routerLink]="['/', profile.id, 'setting']"
                  (click)="toggleProfileSelector()"
                  ><span class="icon gear profile-selector__icon"></span
                ></a>
              </div>
            </div>
          }
        </ng-container>
        <ng-container *appHasRole="[roles.SUPER_ADMIN]">
          @for (profile of profiles | filter: term : true; track profile) {
            <span
              [style.--bs-link-color]="customerConfig?.style?.links"
              [style.--bs-link-hover-color]="customerConfig?.style?.links"
            >
              <span class="profile-selector__customer" [innerHtml]="profile.name | highlightTerm: term"></span>
              @for (p of profile.values; track p; let even = $even; let odd = $odd) {
                <div
                  class="profile-selector__line"
                  [ngClass]="{ 'profile-selector__line--odd': odd, 'profile-selector__line--even': even }"
                >
                  <div class="profile-selector__line py-1 px-3">
                    <a title="{{ profile.name }}" [routerLink]="['/', p.id]" (click)="toggleProfileSelector()"
                      ><span [innerHtml]="p.name | highlightTerm: term"></span
                    ></a>
                    <a
                      title="{{ profile.name }}"
                      [routerLink]="['/', p.id, 'setting']"
                      (click)="toggleProfileSelector()"
                      ><span class="icon gear profile-selector__icon"></span
                    ></a>
                  </div>
                </div>
              }
            </span>
          }
        </ng-container>
      </div>
    </div>
  </mat-menu>
</div>
