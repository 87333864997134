<div class="msidebar msidebar__content">
  <div class="p-2 msidebar__logo">
    @if (!customerConfig) {
      <img alt="FuneUp" src="/assets/imgs/logo-funeup.svg" />
    }
    @if (customerConfig) {
      <img alt="" [src]="customerConfig?.logo?.url | apiUrl" />
    }
  </div>
  <mat-nav-list>
    @if (isSubcategory()) {
      <a mat-list-item [routerLink]="profileId ? ['/', profileId, 'home'] : ['/computed-stats']">
        <span class="msidebar__label d-flex align-items-center" matListItemLine>
          <mat-icon class="msidebar__icon me-2" svgIcon="arrow_left"></mat-icon>Retour</span
        >
      </a>
    }
    @for (link of linkTree; track link; let i = $index) {
      @switch (link.type) {
        @case ('back') {
          <a mat-list-item [routerLink]="link.url">
            <span class="sidebar__label d-flex align-items-center" matListItemLine>
              <mat-icon class="msidebar__icon me-2" svgIcon="arrow_left"></mat-icon>{{ link.label }}</span
            >
          </a>
        }
        @case ('title') {
          <div mat-list-item>
            <span class="msidebar__label px-3 mt-2 mb-2 fs-6" matListItemLine>{{ link.label }}</span>
          </div>
        }
        @default {
          @if (isSubcategory()) {
            <div class="msidebar__title">
              <span class="msidebar__label fw-bolder" matSubheader>{{ link.label }}</span>
            </div>
          }
          @if (!isSubcategory()) {
            <ng-container
              *ngTemplateOutlet="isLink; context: { link: link, collapse: false, collapsed: false }"
            ></ng-container>
          }
          @if (isSubcategory() && link.subNav) {
            @for (subLink of link.subNav; track subLink) {
              <ng-container
                *ngTemplateOutlet="isLink; context: { link: subLink, collapse: false, collapsed: true }"
              ></ng-container>
              @if (subLink.subNav) {
                @for (subsubLink of subLink.subNav; track subsubLink) {
                  <ng-container
                    *ngTemplateOutlet="isLink; context: { link: subsubLink, collapse: true, collapsed: false }"
                  ></ng-container>
                }
              }
            }
          }
        }
      }
    }
    <ng-template #isLink let-collapse="collapse" let-collapsed="collapsed" let-link="link">
      <ng-container *appHasRole="link.requiredRoles">
        @if (link.label !== 'Déposer un avis') {
          <a
            #rla="routerLinkActive"
            class="msidebar__link"
            mat-list-item
            routerLinkActive
            [class.active]="rla.isActive"
            [class.collapse]="collapse"
            [class.collapsed]="
              collapsed && (rla.isActive || (link.activeLink && link.activeLink.includes(router.url.split('?')[0])))
            "
            [class.not-collapsed]="collapsed && !rla.isActive"
            [class.ps-4]="collapse"
            [queryParams]="link.queryParams"
            [queryParamsHandling]="link.queryParamsHandling"
            [routerLink]="link.url"
            [target]="link.target ? link.target : '_self'"
            (click)="menuClick.emit()"
          >
            @if (
              (!collapsed || !link.subNav) &&
              ((link.activeLink && link.activeLink.includes(router.url.split('?')[0])) || rla.isActive)
            ) {
              <span class="indicator" [style.background-color]="customerConfig?.style.selectedMenu"></span>
            }
            <mat-icon class="msidebar__icon me-3" [svgIcon]="link.icon"></mat-icon>
            <span class="msidebar__label flex-grow-1">{{ link.label }}</span>
            @if (link.subNav) {
              <mat-icon
                class="msidebar__icon ms-2"
                [svgIcon]="
                  collapsed && (rla.isActive || (link.activeLink && link.activeLink.includes(router.url.split('?')[0])))
                    ? 'arrow_down'
                    : 'arrow_right'
                "
              ></mat-icon>
            }
          </a>
        }
        @if (link.label === 'Déposer un avis') {
          <a
            #rla="routerLinkActive"
            mat-list-item
            routerLinkActive
            [href]="link.url"
            [target]="link.target ? link.target : '_self'"
            (click)="menuClick.emit()"
          >
            <mat-icon class="msidebar__icon me-3" [svgIcon]="link.icon"></mat-icon>
            <span class="msidebar__label flex-grow-1">{{ link.label }}</span>
            @if (link.subNav) {
              <mat-icon class="msidebar__icon ms-2" svgIcon="arrow_right"></mat-icon>
            }
          </a>
        }
      </ng-container>
    </ng-template>
  </mat-nav-list>
</div>
