import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, inject, LOCALE_ID, NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/** MODULES */
import { CookieModule } from 'ngx-cookie';
import { AppRoutingModule } from './app-routing.module';
/** COMPONENTS */
import { AppComponent } from './app.component';
/** ENVIRONMENTS */
import { environment } from '../environments/environment';
/** INTERCEPTORS */
import { OauthInterceptor } from './interceptors/oauth.interceptor';
/** MODELS */
import { modalOptions } from '@models/modalOptions';
/** SERVICES */
import { FormService } from '@services/form.service';
import { OauthService } from '@services/oauth.service';
import { TokenCookieStorageService } from '@services/token-cookie-storage.service';
/** PIPES */
import { CondolencePipe } from '@pipes/condolence.pipe';
import { PrefixPipe } from '@pipes/prefix.pipe';
import { ShopBooleanPipe } from '@pipes/shop-boolean.pipe';
/** ANGULAR MATERIAL */
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
/** HELPERS */
/** INTERNATIONALIZATION */
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MY_FORMAT } from '@modules/shared.module';
import { ProfilesResolver } from '@resolvers/profiles.resolver';
import { GlobalErrorHandler } from '@utils/global-error-handler';
import { MatPaginatorIntlFr } from './internationalization/angular-material-paginator';

registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent],
  exports: [BrowserAnimationsModule, BrowserModule],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CookieModule.forRoot({
      path: '/',
      domain: environment.cookieDomain,
    }),
    GoogleMapsModule,
    CondolencePipe,
    PrefixPipe,
    ShopBooleanPipe,
  ],
  providers: [
    OauthService,
    TokenCookieStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OauthInterceptor,
      multi: true,
    },
    CurrencyPipe,
    PrefixPipe,
    FormService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: modalOptions },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    ShopBooleanPipe,
    CondolencePipe,
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlFr,
    },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    ProfilesResolver,
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  ],
})
export class AppModule {
  private _adapter: DateAdapter<MomentDateAdapter> = inject(DateAdapter);

  constructor() {
    this._adapter.setLocale('fr');
  }
}
