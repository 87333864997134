import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Token } from '@models/token';
import { OauthService } from '@services/oauth.service';
import { TokenCookieStorageService } from '@services/token-cookie-storage.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class OauthInterceptor implements HttpInterceptor {
  private tokenRetrieved: BehaviorSubject<Token> = new BehaviorSubject<Token>(null);
  private isTokenRetrieving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private tokenErrorsCounter = 0;

  private readonly tokenStorage: TokenCookieStorageService = inject(TokenCookieStorageService);
  private readonly oauthService: OauthService = inject(OauthService);
  private router: Router = inject(Router);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const whiteListedUrls = [
      environment.apiUrl + '/oauth/v2/token',
      environment.apiUrl + OauthService.endpointRequestPassword,
      environment.apiUrl + '/style/family-space.json',
      environment.apiUrl + '/space/profile',
    ];

    // Handle the API OAuth Token requests
    if (
      whiteListedUrls.includes(req.url) ||
      req.url.includes('/admin/resetting/reset') ||
      req.url === environment.apiUrl + '/oauth/v2/token' ||
      req.url === environment.apiUrl + OauthService.endpointRequestPassword ||
      req.url.startsWith(environment.apiUrl + '/admin/resetting/reset/') ||
      req.url.startsWith(environment.apiUrl + '/style/customer?domain=')
    ) {
      return next.handle(req);
    }

    // Handle the API requests
    if (req.url.startsWith(environment.apiUrl)) {
      return this.handleRequestWithTokenManagementFlow(req, next);
    }

    return next.handle(req);
  }

  private handleRequestWithTokenManagementFlow(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isTokenRetrieving.getValue()) {
      return this.waitTokenBeforeHandleRequest(req, next);
    }

    if (this.tokenStorage.token) {
      if (this.tokenStorage.token.expiresAt && this.tokenStorage.token.expiresAt.getTime() <= new Date().getTime()) {
        // The token is expired

        if (this.tokenStorage.token.refreshToken) {
          // A refresh token is available, so refresh the token before handle the request
          return this.retrieveTokenBeforeHandleRequest(req, next, () =>
            this.oauthService.authenticateWithRefreshToken(this.tokenStorage.token.refreshToken),
          );
        }

        this.oauthService.destroyCredentials();
        this.redirectToLogin();

        return of(null);
      }

      // Here the access token is not expired or we don't have more information about its TTL

      if (this.tokenStorage.token.accessToken) {
        return next
          .handle(req.clone({ setHeaders: { Authorization: `Bearer ${this.tokenStorage.token.accessToken}` } }))
          .pipe(
            catchError(requestError => {
              if (
                requestError instanceof HttpErrorResponse &&
                requestError.error &&
                typeof requestError.error === 'object' &&
                Object.prototype.hasOwnProperty.call(requestError.error, 'error_description')
              ) {
                switch (requestError.error.error_description) {
                  case 'The access token provided has expired.':
                  case 'The access token provided is invalid.':
                    // The access token is expired or invalid, so get a client token or refresh it
                    if (this.tokenStorage.token && this.tokenStorage.token.refreshToken) {
                      return this.retrieveTokenBeforeHandleRequest(req, next, () =>
                        this.oauthService.authenticateWithRefreshToken(this.tokenStorage.token.refreshToken),
                      );
                    }

                    this.oauthService.destroyCredentials();
                    this.redirectToLogin();

                    return of(null);
                  case 'Refresh token has expired':
                  case 'The refresh token is invalid.':
                    // Here there is no access token nor refresh token available, so get a client access token before handle the request

                    this.oauthService.destroyCredentials();
                    this.redirectToLogin();

                    return of(null);
                }
              }

              // Here the error could not be handled by the token management flow, so just re-throw it
              return throwError(requestError);
            }),
          );
      }

      if (this.tokenStorage.token.refreshToken) {
        return this.retrieveTokenBeforeHandleRequest(req, next, () =>
          this.oauthService.authenticateWithRefreshToken(this.tokenStorage.token.refreshToken),
        );
      }
    }

    this.oauthService.destroyCredentials();
    this.redirectToLogin();

    return of(null);
  }

  private waitTokenBeforeHandleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.isTokenRetrieving.pipe(
      filter((isTokenRetrieving: boolean) => !isTokenRetrieving),
      switchMap(() => this.tokenRetrieved),
      take(1),
      switchMap((token: Token) => {
        // Since we always next the token before the boolean,
        // Here we are not locked anymore but the token can be null if an error occurred when retrieving a token
        if (token && token.accessToken) {
          return next.handle(req.clone({ setHeaders: { Authorization: `Bearer ${token.accessToken}` } }));
        }

        // This request will result in an error because we could not retrieve a token :(
        // Probably a server issue
        return next.handle(req);
      }),
    );
  }

  private retrieveTokenBeforeHandleRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    tokenObsCtor: () => Observable<Token>,
  ): Observable<HttpEvent<any>> {
    if (this.isTokenRetrieving.getValue()) {
      return this.waitTokenBeforeHandleRequest(req, next);
    }

    this.tokenRetrieved.next(null);
    this.isTokenRetrieving.next(true);

    return tokenObsCtor().pipe(
      catchError(tokenError => {
        this.oauthService.destroyCredentials();
        this.redirectToLogin();

        this.tokenErrorsCounter++;

        // If getting a token result in error 5 times, throw the error
        // This security avoid infinite loops
        // Basically, it will happen only when getting a client access token result in error 5 times in a row...
        // Since it's a constant, it cas be adjusted according the needs
        if (this.tokenErrorsCounter > 5) {
          return throwError(tokenError);
        }

        return of(null);
      }),
      finalize(() => {
        this.isTokenRetrieving.next(false);
        this.tokenErrorsCounter = 0;
      }),
      switchMap((token: Token) => {
        this.tokenRetrieved.next(token);

        return next.handle(req.clone({ setHeaders: { Authorization: `Bearer ${token.accessToken}` } }));
      }),
    );
  }

  private redirectToLogin(): void {
    this.router.navigate(['/signin'], { queryParams: { callback: window.location.href } });
  }
}
