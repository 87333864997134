import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
/** RXJS */
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
/** SERVICES */
import { BaseService } from '@services/base.service';
/** MODELS */
import { ProfileConfig } from '@models/profile-config.data-list';

@Injectable()
export class ProfileConfigResolver {
  private readonly service = inject(BaseService);
  private router = inject(Router);

  private endpoint = '/admin/profile-config/';

  resolve(route: ActivatedRouteSnapshot): Observable<ProfileConfig | null> {
    return this.service.get(this.endpoint + route.params.profileId).pipe(
      catchError(() => {
        this.router.navigate(['/unauthorized']);
        return of(null);
      }),
    );
  }
}
