import { Pipe, PipeTransform } from '@angular/core';
import { CeremonTypes } from '@models/ceremony-type';

@Pipe({
  name: 'ceremony',
  standalone: true,
})
export class CeremonyPipe implements PipeTransform {
  private ceremonies = CeremonTypes;
  name: any;

  transform(value: any): any {
    this.ceremonies.map(val => {
      if (val.value === value) {
        this.name = val.name;
      }
    });
    return this.name;
  }
}
