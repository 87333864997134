import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml',
  standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {
  private sanitized: DomSanitizer = inject(DomSanitizer);
  transform(value: string) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
