import { inject, Injectable } from '@angular/core';
import { CustomerConfig } from '@models/customer-config';
import { BaseService } from '@services/base.service';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerConfigResolver {
  private baseService = inject(BaseService);

  resolve(): Observable<null | CustomerConfig> {
    if (window.location.host !== environment.url) {
      return this.baseService.get(`/style/customer?domain=${window.location.hostname}`);
    } else {
      return of(null);
    }
  }
}
