import { Pipe, PipeTransform } from '@angular/core';
import { Profile } from '@models/profile.data-list';

@Pipe({
  name: 'filter',
  standalone: true,
})
export class FilterPipe implements PipeTransform {
  transform(value: any, term: string, includeCustomer): any {
    if (term && term !== '' && value && value.length) {
      const lowerTerm = term.toLowerCase();
      const profilesFiltered = value.filter(item =>
        includeCustomer
          ? item.customer.name.toLowerCase().includes(lowerTerm) || item.name.toLowerCase().includes(lowerTerm)
          : item.name.toLowerCase().includes(lowerTerm),
      );

      return includeCustomer ? this.groupByCustomer(profilesFiltered) : profilesFiltered;
    }

    return value && includeCustomer ? this.groupByCustomer(value) : value;
  }

  groupByCustomer(profiles: Profile[]): any {
    return profiles.reduce((objectsByKeyValue, obj: Profile) => {
      const index = objectsByKeyValue.findIndex(o => o.key === obj.customer.id);

      if (index !== -1) {
        objectsByKeyValue[index].values.push(obj);
      } else {
        objectsByKeyValue.push({
          key: obj.customer.id,
          name: obj.customer.name,
          values: [obj],
        });
      }

      return objectsByKeyValue;
    }, []);
  }
}
