<mat-form-field>
  <mat-label>
    {{ filter.label }}
  </mat-label>
  <input
    aria-label=""
    matInput
    type="text"
    [formControl]="control"
    [matAutocomplete]="auto"
    [placeholder]="filter.placeholder"
  />
  @if (control.value && !isLoading) {
    <button aria-label="Clear" mat-icon-button matSuffix (click)="clearControl()">
      <mat-icon>close</mat-icon>
    </button>
  }
  @if (isLoading) {
    <mat-icon matSuffix>
      <mat-spinner diameter="20"></mat-spinner>
    </mat-icon>
  }
  <mat-autocomplete
    #auto="matAutocomplete"
    [autoSelectActiveOption]="true"
    [displayWith]="formatLabel.bind(this)"
    (optionSelected)="updateControl($event)"
  >
    @for (option of (filteredData | async)?.results; track option) {
      <mat-option [value]="option.id">
        {{ option.name }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
