import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'condolence',
  standalone: true,
})
export class CondolencePipe implements PipeTransform {
  transform(value: number): any {
    return value === 0 ? '-' : `${value} hommage${value > 1 ? 's' : ''}`;
  }
}
