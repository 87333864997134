import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Services } from '@models/services';
import { ProfileService } from '@services/profile.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileServicesResolver {
  private router = inject(Router);
  private profileService = inject(ProfileService);

  resolve(route: ActivatedRouteSnapshot): Observable<Services | null> {
    return this.profileService.getProfileServices(route.params.profileId).pipe(
      catchError(() => {
        this.router.navigate(['/unauthorized']);
        return of(null);
      }),
    );
  }
}
