import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
/** COMPONENTS */
import { FilterComponent } from '@components/filter/filter.component';
/** PIPES */
import { CeremonyPipe } from '@pipes/ceremony-type.pipe';
import { CondolenceStatePipe } from '@pipes/condolence-state.pipe';
import { ConvertBooleanPipe } from '@pipes/convert-boolean.pipe';
import { ConvertTypeToTypeNamePipe } from '@pipes/convert-type-place-name.pipe';
import { CountryPipe } from '@pipes/country.pipe';
import { EnumPipe } from '@pipes/enum.pipe';
import { FamilySpaceStepTypePipe } from '@pipes/family-space-step-type.pipe';
import { KeyPipe } from '@pipes/key.pipe';
/** DIRECTIVES */
import { DragAndDropDirective } from '@directives/drag-and-drop.directive';
import { HasRoleDirective } from '@directives/has-role.directive';
/** ANGULAR MATERIALS */
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDateFormats, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FilterDynamicListComponent } from '@components/filter/filter-dynamic-list/filter-dynamic-list.component';
import { MbreadcrumbComponent } from '@components/mbreadcrumb/mbreadcrumb.component';
import { MconfirmModalComponent } from '@components/mconfirm-modal/mconfirm-modal.component';
import { ApiUrlPipe } from '@pipes/api-url.pipe';
import { DynamicPipe } from '@pipes/dynamic.pipe';
import { FilterPipe } from '@pipes/filter.pipe';
import { HexaToRgbaPipe } from '@pipes/hexa-to-rgba.pipe';
import { HighlightTermPipe } from '@pipes/highlight-term.pipe';
import { Nl2brPipe } from '@pipes/nl2br.pipe';
import { SafeHtmlPipe } from '@pipes/sanitize.pipe';
import { StateBooleanPipe } from '@pipes/state-boolean.pipe';
import { StateLabelPipe } from '@pipes/state-label.pipe';
import { StateUdianaPipe } from '@pipes/state-udiana.pipe';
import { StylePropertiesTranslatorPipe } from '@pipes/style-properties-translator.pipe';

export const MY_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    GoogleMapsModule,
    MatDialogModule,
    MatTooltipModule,
    MatSortModule,
    MatTabsModule,
    MatTableModule,
    MatListModule,
    MatCheckboxModule,
    MatMenuModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatStepperModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatGridListModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatSidenavModule,
    MatChipsModule,
    MatRadioModule,
    MatSliderModule,
    MatBadgeModule,
    HexaToRgbaPipe,
    FilterDynamicListComponent,
    FilterComponent,
    ApiUrlPipe,
    MbreadcrumbComponent,
    MconfirmModalComponent,
    HasRoleDirective,
    DragAndDropDirective,
    CeremonyPipe,
    CondolenceStatePipe,
    ConvertBooleanPipe,
    ConvertTypeToTypeNamePipe,
    CountryPipe,
    StateBooleanPipe,
    KeyPipe,
    SafeHtmlPipe,
    EnumPipe,
    FamilySpaceStepTypePipe,
    DynamicPipe,
    FilterPipe,
    HighlightTermPipe,
    StateLabelPipe,
    StylePropertiesTranslatorPipe,
    Nl2brPipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    StateBooleanPipe,
    KeyPipe,
    SafeHtmlPipe,
    HasRoleDirective,
    DragAndDropDirective,
    CountryPipe,
    CeremonyPipe,
    ConvertTypeToTypeNamePipe,
    ConvertBooleanPipe,
    CondolenceStatePipe,
    EnumPipe,
    FamilySpaceStepTypePipe,
    MatDialogModule,
    MatTabsModule,
    MatTooltipModule,
    MatSortModule,
    MatTableModule,
    MatListModule,
    MatCheckboxModule,
    MatMenuModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatStepperModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatGridListModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    ApiUrlPipe,
    DynamicPipe,
    MatToolbarModule,
    MatSidenavModule,
    FilterPipe,
    HighlightTermPipe,
    MbreadcrumbComponent,
    MatChipsModule,
    StateLabelPipe,
    MatRadioModule,
    MatSliderModule,
    StylePropertiesTranslatorPipe,
    MatBadgeModule,
    Nl2brPipe,
    HexaToRgbaPipe,
    FilterComponent,
  ],
  providers: [
    StylePropertiesTranslatorPipe,
    StateLabelPipe,
    DatePipe,
    StateBooleanPipe,
    CeremonyPipe,
    EnumPipe,
    DynamicPipe,
    ApiUrlPipe,
    SafeHtmlPipe,
    Nl2brPipe,
    StateUdianaPipe,
  ],
})
export class SharedModule {}
