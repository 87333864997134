import { inject, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Style as StyleDeathNotice } from '@models/death-notice-customization';
import { Style as StyleFamilySpace } from '@models/family-space-customization';
import { Style as StyleShop } from '@models/style-customization';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  form: UntypedFormGroup;
  showBreadcrumbButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  submitted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

  private fb: UntypedFormBuilder = inject(UntypedFormBuilder);

  init(params: any, validators?: any): FormGroup {
    this.form = this.fb.group(params, validators);
    return this.form;
  }

  isInvalidField(name: string): boolean {
    return this.isInvalidFieldInForm(this.form, name);
  }

  isInvalidFieldInForm(form: UntypedFormGroup, name: string): boolean {
    const control = form.get(name);

    if (!control) {
      return true;
    }

    return (control.dirty || control.touched) && control.invalid;
  }

  setErrors(errors: any, form = this.form): void {
    if (errors) {
      for (const k of Object.keys(errors)) {
        const control = form.get(k);
        console.log('k', k);
        console.log('form', form);
        if (control instanceof FormArray) {
          for (const ka of Object.keys(errors[k])) {
            if (control.controls[ka] instanceof FormGroup) {
              const formGroup = control.controls[ka] as FormGroup;
              for (const kb of Object.keys(errors[k][ka])) {
                formGroup.controls[kb].setErrors({
                  apiErrors: errors[k][ka][kb],
                });
              }
            } else {
              // childControl is a FormControl
              control.controls[ka].setErrors({ apiErrors: errors[k][ka] });
            }
          }
        } else if (control instanceof FormGroup) {
          for (const ka of Object.keys(errors[k])) {
            control.get(ka).setErrors({ apiErrors: errors[k][ka] });
          }
        } else {
          if (control) {
            control?.setErrors({ apiErrors: errors[k] });
          }
        }
      }
      form.markAllAsTouched();
      form.updateValueAndValidity();
    }
  }

  setApiErrors(errors: any, form = this.form): void {
    if (errors) {
      for (const k of Object.keys(errors)) {
        const control = form.get(k);

        if (control instanceof FormGroup) {
          this.setApiErrors(errors[k], control);
        } else if (control instanceof FormArray) {
          for (let i = 0; i < control.length; i++) {
            const group = control.at(i) as FormGroup;
            this.setApiErrors(errors[k][i], group);
          }
        } else {
          control.setErrors({ apiErrors: errors[k] });
        }
      }

      form.markAllAsTouched();
      form.updateValueAndValidity();
    }
  }

  buildCheckbox(formArray: FormArray, list: any, key: string): void {
    list.forEach(item => {
      const checked = item[key] === true;
      formArray.push(new FormControl(checked));
    });
  }

  fillFormProperties(data: Partial<StyleShop | StyleFamilySpace | StyleDeathNotice>, form: UntypedFormGroup): void {
    for (const styleKey in data) {
      if (Object.prototype.hasOwnProperty.call(data, styleKey)) {
        const value = data[styleKey];
        if (typeof value === 'object') {
          form.addControl(styleKey, this.fb.group(value));
        } else {
          form.addControl(styleKey, this.fb.control(value));
        }
      }
    }
  }
}
