import { Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import { User } from '@models/user.data-list';

import { NgIf } from '@angular/common';
import { MatButton, MatIconAnchor, MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatToolbar } from '@angular/material/toolbar';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ProfileSelectorComponent } from '@components/profile-selector/profile-selector.component';
import { HasRoleDirective } from '@directives/has-role.directive';
import { CustomerConfig } from '@models/customer-config';
import { UserRoles } from '@models/user-roles';
import { MediaQueriesService } from '@services/media-queries.service';
import { OauthService } from '@services/oauth.service';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-mheader',
  templateUrl: './mheader.component.html',
  styleUrls: ['./mheader.component.scss'],
  standalone: true,
  imports: [
    MatToolbar,
    MatIcon,
    MatMenuTrigger,
    MatIconAnchor,
    MatTooltip,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    RouterLink,
    MatDivider,
    ProfileSelectorComponent,
    NgIf,
    MatButton,
    HasRoleDirective,
  ],
})
export class MheaderComponent implements OnInit, OnDestroy {
  private oauthService = inject(OauthService);
  private activatedRoute = inject(ActivatedRoute);
  private mediaQueriesService = inject(MediaQueriesService);

  public currentUser: User;
  public roles = UserRoles;
  unsubscribe: Subscription[] = [];
  customerConfig: CustomerConfig;
  @Output() menuClick = new EventEmitter();
  isMobile = false;

  ngOnInit(): void {
    this.customerConfig = this.activatedRoute.snapshot.data.customerConfig;
    this.watchMediaQueries();
    this.initUser();
  }

  watchMediaQueries(): void {
    const sub = this.mediaQueriesService
      .getCurrentMediaQueries()
      .pipe(
        tap(currentMediaQuerie => {
          this.isMobile = currentMediaQuerie === 'sm' ? true : false;
        }),
      )
      .subscribe();
    this.unsubscribe.push(sub);
  }

  initUser(): void {
    const sub = this.oauthService
      .getAuthenticatedUser()
      .pipe(
        filter(u => !!u),
        tap((user: User) => {
          this.currentUser = user;
        }),
      )
      .subscribe();
    this.unsubscribe.push(sub);
  }

  logout(): void {
    this.oauthService.logout();
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => (sb && !sb.closed ? sb.unsubscribe() : null));
  }
}
