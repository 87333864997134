import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enum',
  standalone: true,
})
export class EnumPipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (Object.prototype.hasOwnProperty.call(args, value)) {
      return args[value];
    } else {
      return value;
    }
  }
}
