import { Pipe, PipeTransform } from '@angular/core';
import { CondolenceState } from '@models/condolence-state';

@Pipe({
  name: 'condolenceState',
  standalone: true,
})
export class CondolenceStatePipe implements PipeTransform {
  transform(value: string): any {
    const state = CondolenceState.find(params => params.value === value);
    return state.label;
  }
}
