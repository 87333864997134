import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
/** MODELS */
import { Profile } from '@models/profile.data-list';
/** SERVICES */
/** RXJS */
import { Subscription } from 'rxjs';
/** DECORATORS */
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { CustomerConfig } from '@models/customer-config';
import { UserRoles } from '@models/user-roles';
import { SharedModule } from '@modules/shared.module';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-profile-selector',
  templateUrl: './profile-selector.component.html',
  styleUrls: ['./profile-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatMenuTrigger, MatButton, MatMenu, MatFormField, MatInput, FormsModule, NgClass, RouterLink, SharedModule],
})
export class ProfileSelectorComponent implements OnInit, OnDestroy {
  static endpoint = '/admin/profile/';
  roles = UserRoles;
  currentProfile: Profile;
  profiles: Profile[];
  term: string;
  unsubscribe: Subscription[] = [];
  customerConfig: CustomerConfig;

  @ViewChild(MatMenuTrigger) menuProfile: MatMenuTrigger;

  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private router: Router = inject(Router);

  constructor() {
    const sub = this.router.events
      .pipe(
        distinctUntilChanged((prev, curr) => !!prev && !!curr && prev === curr),
        filter(e => e instanceof NavigationEnd),
        map(() => this.activatedRoute),
        filter(route => !!route.firstChild),
        map(route => route.firstChild.firstChild),
        switchMap(route => route.data),
        filter(value => value !== undefined),
        tap(data => {
          if (data.profile) {
            this.currentProfile = data.profile;
          } else {
            this.currentProfile = null;
          }
          this.profiles = data.profiles ? data.profiles : null;

          this.cdr.detectChanges();
        }),
      )
      .subscribe();

    this.unsubscribe.push(sub);
  }

  ngOnInit(): void {
    this.customerConfig = this.activatedRoute.snapshot.data.customerConfig;
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => (sb && !sb.closed ? sb.unsubscribe() : null));
  }

  toggleProfileSelector(): void {
    this.menuProfile.toggleMenu();
  }
}
