import { Pipe, PipeTransform } from '@angular/core';
import { familySpaceStepTypeTranslations } from '@models/family-space-step-type';

@Pipe({
  name: 'familySpaceStepType',
  standalone: true,
})
export class FamilySpaceStepTypePipe implements PipeTransform {
  private types = familySpaceStepTypeTranslations;

  transform(value: any): any {
    return this.types[value];
  }
}
