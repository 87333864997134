import { Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ConfirmModalData } from '@models/confirm-modal-data';

@Component({
  selector: 'app-mconfirm-modal',
  templateUrl: './mconfirm-modal.component.html',
  imports: [MatDialogClose, MatButton, MatDialogActions, MatDialogTitle, MatDialogContent],
  standalone: true,
})
export class MconfirmModalComponent {
  public data: ConfirmModalData = inject(MAT_DIALOG_DATA);
}
