import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
/** RXJS */
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
/** SERVICES */
import { BaseService } from '@services/base.service';
/** MODELS */
import { Profile, ProfileList } from '@models/profile.data-list';

@Injectable()
export class ProfilesResolver {
  private readonly service = inject(BaseService);
  private router = inject(Router);

  private endpoint = '/admin/profile/';

  resolve(): Observable<Profile[] | null> {
    return this.service
      .get(this.endpoint, {
        params: {
          maxPerPage: 3000,
        },
      })
      .pipe(
        switchMap((data: ProfileList) => {
          return of(data.results);
        }),
        catchError(() => {
          this.router.navigate(['/unauthorized']);
          return of(null);
        }),
      );
  }
}
