import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertTypeToTypeName',
  standalone: true,
})
export class ConvertTypeToTypeNamePipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case 'collation':
        return 'Collation';

      case 'cremation':
        return 'Crémation';

      case 'ashes':
        return 'Destination des cendres';

      case 'custom':
        return 'Évènement personnalisé';

      case 'inhumation':
        return 'Inhumation';

      case 'resting_place':
        return 'Lieu de repos';

      case 'coffin_placing':
        return 'Mise en bière';

      case 'recollection':
        return 'Recueillement';
    }
  }
}
